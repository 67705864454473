<template>
	<div class="ccsqdTpl">
		<h4>查看出差申请单模板</h4>

		<table class="main-table">
			<tbody>
				<tr v-for="(tplItem,tplIndex) in tplList" :key="'tpl'+ tplIndex">
					<td class="div-left">
						{{tplItem.name}}
					</td>
					<td :class="calcTdClass(tplItem)">
						<div v-show="tplItem.type != Array">{{tplItem.attrName}}</div>
						<div v-show="tplItem.type == Array">
							<table class="inner-table">
								<tbody>
									<tr v-for="(tplChildItem,tplChildIndex) in tplItem.children"
										:key="'tplChildIndex' + tplChildIndex">
										<td class="div-left">{{tplChildItem.name}}</td>
										<td>{{tplChildItem.attrName}}</td>
									</tr>
								</tbody>
							</table>

						</div>
					</td>
				</tr>
				<tr v-for="(spjdItem,spjdIndex) in spjdList" :key="'spjdlist' + spjdIndex">
					<td>{{spjdItem.name}}</td>
					<td>
						<div class="steps">
							<div class="el-steps sp-item el-steps--vertical">
								<div class="el-step is-vertical" style="flex-basis: 100%;">
									<div class="el-step__head is-success">
										<div class="el-step__line" style="margin-right: 0px;"><i
												class="el-step__line-inner"
												style="transition-delay: 0ms; border-width: 1px; height: 100%;"></i>
										</div>
										<div class="el-step__icon is-text"><i
												class="el-step__icon-inner is-status el-icon-check"></i></div>
									</div>
									<div class="el-step__main">
										<div class="el-step__title is-success">提交审批</div>
										<div class="el-step__description is-success">
											<span>$sentTime</span>
											<span>$sentEmpName</span>
										</div>
									</div>
								</div><!---->
								<div class="el-step is-vertical is-flex" style="flex-basis: 100%;">
									<div class="el-step__head is-success">
										<div class="el-step__line"><i class="el-step__line-inner"></i></div>
										<div class="el-step__icon is-text"><i
												class="el-step__icon-inner is-status el-icon-check"></i></div>
									</div>
									<div class="el-step__main">
										<div class="el-step__title is-success"></div>
										<div class="el-step__description is-success">
											<div>
												<div class="font16">
													<span>已通过$flowState</span>
												</div>
												<div style="display: flex;">
													<span>$completeTime</span>
													<span>审批人:$approveEmpName</span>
													<span>审批意见:$appRemark</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="el-step is-vertical is-flex" style="flex-basis: 100%;">
									<div class="el-step__head is-finish">
										<div class="el-step__line"><i class="el-step__line-inner"></i></div>
										<div class="el-step__icon is-text"><!---->
											<div class="el-step__icon-inner">2</div>
										</div>
									</div>
									<div class="el-step__main">
										<div class="el-step__title is-finish"></div>
										<div class="el-step__description is-finish">
											<div >
												<div  class="font16">
													<span>审批中$flowState</span>
												</div>
												<div>
													<div> 审批人:$approveEmpName</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="el-step is-vertical is-flex" style="flex-basis: 100%;">
									<div class="el-step__head is-error">
										<div class="el-step__line"><i class="el-step__line-inner"></i></div>
										<div class="el-step__icon is-text"><i
												class="el-step__icon-inner is-status el-icon-close"></i></div>
									</div>
									<div class="el-step__main">
										<div class="el-step__title is-error"></div>
										<div class="el-step__description is-error">
											<div >
												<div  class="font16">
													<span>已拒绝</span>
												</div>
												<div style="display: flex;">
													<span>$completeTime</span>
													<span>审批人:$approveEmpName</span>
													<span>审批意见:$appRemark</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</td>
				</tr>
			</tbody>



		</table>
		<!-- 打印底座 -->
		<p class="print-info">
			<span class="print-item" v-for="(printItem,printIndex) in printList" :key="'print'+ printIndex">
				<span>{{printItem.name}}</span><span>{{printItem.attrName}}</span>
			</span>


		</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ccsqdDetail: {}, // 出差申请单详情
				tplList: [],
				spjdList: [],
				printList: [],
			}
		},
		mounted() {
			this.initTplList();

			this.initPrintList();
			this.initSpjdList();
		},
		methods: {
			// 初始化模板list
			initTplList() {

				this.tplList.push({
					name: '出差单号',
					attrName: "$ccsqdNo",
					type: String
				});
				this.tplList.push({
					name: '出差人',
					attrName: "$ccr",
					type: String
				});
				this.tplList.push({
					name: '同行人',
					attrName: "$sxr",
					type: String
				});
				this.tplList.push({
					name: '常驻地',
					attrName: "$mdd",
					type: String
				});
				this.tplList.push({
					name: '出差日期',
					attrName: "$dateBegin - $dateEnd",
					type: String
				});
				this.tplList.push({
					name: '成本中心',
					attrName: "$costCenterName",
					type: String
				});
				// this.tplList.push({name:'常驻地',attrName:"$mdd",type:String});
				this.tplList.push({
					name: '出差范围',
					attrName: "$area",
					type: String
				});
				this.tplList.push({
					name: '出差事由',
					attrName: "$ccsy",
					type: String
				});
				this.tplList.push({
					name: '行程计划',
					attrName: "$fastDetailsRouteList",
					type: Array,
					children: [{
							name: '【$index】',
							attrName: "$proType行程",
							type: String
						},
						{
							name: '行程时间:',
							attrName: "$departDateLong - $arriveDateLong ,共$dayDiff天",
							type: String
						},
						{
							name: '出行人:',
							attrName: "$cxr",
							type: String
						},
						{
							name: '行程:',
							attrName: "$departCityName - $arriveCityName",
							type: String
						},
						{
							name: '航班号/车次:',
							attrName: "$flightNo",
							type: String
						},
						{
							name: '舱位/席别:',
							attrName: "$cabin",
							type: String
						},
						{
							name: '国内交通费:',
							attrName: "$flightNo",
							type: String
						},
						{
							name: '打车费:',
							attrName: "$dcf",
							type: String
						},
						{
							name: '出差类型:',
							attrName: "$cclxMc",
							type: String
						},
						{
							name: '出差事由:',
							attrName: "$ccsy",
							type: String
						},
						{
							name: '住宿说明:',
							attrName: "$zssm",
							type: String
						},
						{
							name: '订单号:',
							attrName: "$orderNo",
							type: String
						},
						{
							name: '合计:',
							attrName: "$fyys",
							type: String
						},
					],
				});
				this.tplList.push({
					name: '住宿安排',
					attrName: "ccsqdNo",
					type: Array,
					children: [{
							name: '【$index】',
							attrName: "$proType行程",
							type: String
						},
						{
							name: '住宿日期:',
							attrName: "$departDate - $arriveDate ,共$dayDiff天",
							type: String
						},
						{
							name: '入住城市:',
							attrName: "$departCityName",
							type: String
						},
						{
							name: '入住人:',
							attrName: "$cxr",
							type: String
						},

						{
							name: '酒店名称:',
							attrName: "$flightNo",
							type: String
						},
						{
							name: '房型:',
							attrName: "$cabin",
							type: String
						},
						{
							name: '房间数:',
							attrName: "$fjs",
							type: String
						},
						{
							name: '住宿费:',
							attrName: "$dj",
							type: String
						},
						{
							name: '出差餐费:',
							attrName: "$cf",
							type: String
						},
						{
							name: '住宿说明:',
							attrName: "$zssm",
							type: String
						},
						{
							name: '订单号:',
							attrName: "$orderNo",
							type: String
						},
						{
							name: '合计:',
							attrName: "$fyys",
							type: String
						},
					],
				});
				// this.tplList.push({name:'住宿合计',attrName:"by1",type:String});
				this.tplList.push({
					name: '备注说明',
					attrName: "$by1",
					type: String
				});
				this.tplList.push({
					name: '费用合计',
					attrName: "ccsqdNo",
					type: Array,
					children: [{
							name: '本次总预算:',
							attrName: "$zys",
							type: String
						},
						{
							name: '交通预算:',
							attrName: "$jtys",
							type: String
						},
						{
							name: '住宿预算:',
							attrName: "$zsys",
							type: String
						},
						{
							name: '其他预算:',
							attrName: "$qtys",
							type: String
						},

					],
				});
				


			},
			initPrintList() {
				this.printList.push({
					name: '打印人:',
					attrName: "$creator",
					type: String
				})
				this.printList.push({
					name: '打印时间:',
					attrName: "$createdate",
					type: String
				})
			},

			initSpjdList() {
				this.spjdList.push({
					name: '审批进度:',
					attrName: "$createdate",
					type: Array,
					children:[
						
					]
				})
			},
			// 控制 td class 样式
			calcTdClass(tplItem) {
				let str = '';
				if (tplItem.type == Array) {
					str = 'no-padding';
				}
				return str
			}


		}
	}
</script>

<style scoped>
	
	.ccsqdTpl {
		width: 750px;
		margin: 0 auto;
		font-size: 14px;
	}

	.main-table {
		width: 100%;
		border-collapse: collapse;
	}

	.main-table td,
	.main-table th {
		border: 1px solid rgba(0, 0, 0, .85);
		padding: 3px 20px;
		text-align: left;
	}

	.main-table td.no-padding {
		padding: 0;
	}

	.main-table .div-left {
		width: 150px;
	}

	.inner-table {
		width: 100%;
		border-collapse: collapse;
		border-top: 1px solid rgba(0, 0, 0, .85);
	}

	.inner-table td,
	.inner-table th {
		border: 0px solid rgba(0, 0, 0, .85);
		padding: 3px 20px;
		text-align: left;
	}

	.inner-table .div-left {
		width: 120px;
	}

	.print-info {
		text-align: left;
		padding: 5px 0;
	}

	.print-info .print-item {
		margin-right: 20px;
	}
	.el-steps--vertical {
	    height: 100%;
	    flex-flow: column;
	}
	
	.el-steps {
	    display: flex;
	}
	.el-step.is-vertical {
	    display: flex;
	}
	
	.el-step.is-vertical, .el-steps {
	    display: -ms-flexbox;
	}
	
	.el-step {
	    position: relative;
	    flex-shrink: 1;
	}
	.el-step.is-vertical .el-step__head {
	    flex-grow: 0;
	    width: 24px;
	}
	
	
	.el-step__head {
	    position: relative;
	    width: 100%;
	}
	.el-step.is-vertical .el-step__main {
	    padding-left: 10px;
	    flex-grow: 1;
	}
	
	.el-step__main {
	    white-space: normal;
	    text-align: left;
	}
	.el-step.is-vertical .el-step__title {
	    line-height: 24px;
	    padding-bottom: 8px;
	}

	
	.el-step__description {
	    padding-right: 10%;
	    margin-top: -5px;
	    font-size: 12px;
	    line-height: 20px;
	    font-weight: 400;
	}
	
	.el-step.is-vertical .el-step__head {
	    flex-grow: 0;
	    width: 24px;
	}

	.el-step.is-vertical .el-step__head {
	    flex-grow: 0;
	    width: 24px;
	}

	.el-step__head {
	    position: relative;
	    width: 100%;
	}

	
	
	.el-step.is-vertical .el-step__line {
	    width: 2px;
	    top: 0;
	    bottom: 0;
	    left: 11px;
	}
	
	.el-step__line {
	    position: absolute;
	    border-color: inherit;
	    background-color: #C0C4CC;
	}
	
	
	.el-step__line-inner {
	    display: block;
	    border-width: 1px;
	    border-style: solid;
	    border-color: inherit;
	    transition: .15s ease-out;
	    box-sizing: border-box;
	    width: 0;
	    height: 0;
	}
	.el-step__icon.is-text {
	    border-radius: 50%;
	    border: 2px solid;
	    border-color: inherit;
	}
	
	
	.el-step__icon {
	    position: relative;
	    z-index: 1;
	    display: inline-flex;
	    justify-content: center;
	    align-items: center;
	    width: 24px;
	    height: 24px;
	    font-size: 14px;
	    box-sizing: border-box;
	    background: #FFF;
	    transition: .15s ease-out;
	}
	.el-step__icon-inner {
	    display: inline-block;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    text-align: center;
	    font-weight: 700;
	    line-height: 1;
	    color: inherit;
	}
	
	.el-image-viewer__btn, .el-step__icon-inner {
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	}
	.el-step__head.is-success {
	    color: #67C23A;
	    border-color: #67C23A;
	}
	
	.el-step__title.is-success {
	    color: #67C23A;
	}
	
	.el-step__description.is-success {
	    color: #67C23A;
	}
	
	.el-step__head.is-finish {
	    color: #409EFF;
	    border-color: #409EFF;
	}
	.el-step__title.is-finish {
	    color: #409EFF;
	}
	
	
	.el-step__description.is-finish {
	    color: #409EFF;
	}
	
	
	img.userhead {
	    width: 20px;
	    height: 20px;
	    margin-right: 5px;
	}
	.el-step__head.is-error {
	    color: #F56C6C;
	    border-color: #F56C6C;
	}
	.el-step__title.is-error {
	    color: #F56C6C;
	}
	.el-step__description.is-error {
	    color: #F56C6C;
	}
</style>